import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class NavigationController extends BaseController {
  public folded = false;

  constructor(
    $scope: angular.IScope,
    msNavigationService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    const apf = PermissionService.apf;

    // ----- ACCOUNT -----
    msNavigationService.saveItem('account', {
      group: true,
      title: 'MENU.ACCOUNT',
    });

    msNavigationService.saveItem('account.dashboard', {
      icon: 'icon-view-dashboard',
      id: 'account.dashboard',
      state: 'app.account.dashboard',
      title: 'MENU.DASHBOARD',
    });

    msNavigationService.saveItem('account.sites', {
      icon: 'icon-map-marker-radius',
      id: 'account.sites',
      state: 'app.account.sites',
      title: 'MENU.SITES',
      hidden: () => {
        return !apf.hasSiteView;
      },
    });

    msNavigationService.saveItem('account.groups', {
      icon: 'icon-map-marker-multiple',
      id: 'account.groups',
      state: 'app.account.groups',
      title: 'MENU.GROUPS',
      hidden: () => {
        return !apf.hasGroupView;
      },
    });

    msNavigationService.saveItem('account.infrastructures', {
      icon: 'icon-water-pump',
      id: 'account.infrastructures',
      state: 'app.account.infrastructures',
      title: 'MENU.INFRASTRUCTURES',
      hidden: () => {
        return !apf.hasInfrastructureView;
      },
    });

    msNavigationService.saveItem('account.equipments', {
      icon: 'icon-pocket',
      id: 'account.equipments',
      state: 'app.account.equipments',
      title: 'MENU.EQUIPMENT',
      hidden: () => {
        return !apf.hasEquipmentView;
      },
    });

    // ----- WATER -----
    msNavigationService.saveItem('water', {
      group: true,
      title: 'MENU.WATER',
      hidden: () => {
        return !(
          apf.hasAllocationView ||
          apf.hasIrrigationControllerView ||
          apf.hasIrrigationPlanView ||
          (apf.hasIrrigationOverrideView && apf.hasIrrigationOverride) ||
          apf.hasWaterBudgetView ||
          apf.hasWaterLicenceView
        );
      },
    });

    msNavigationService.saveItem('water.irrigation-plans', {
      icon: 'icon-calendar-multiple',
      id: 'water.irrigation-plans',
      state: 'app.water.irrigation-plans',
      title: 'MENU.IRRIGATION_PLANS',
      hidden: () => {
        return !apf.hasIrrigationPlanView;
      },
    });

    msNavigationService.saveItem('water.allocations', {
      icon: 'icon-water',
      id: 'water.allocations',
      state: 'app.water.allocations',
      title: 'MENU.ALLOCATIONS',
      hidden: () => {
        return !apf.hasAllocationView;
      },
    });

    msNavigationService.saveItem('water.irrigation-controllers', {
      icon: 'icon-deskphone',
      id: 'water.irrigation-controllers',
      state: 'app.water.irrigation-controllers',
      title: 'MENU.IRRIGATION_CONTROLLERS',
      hidden: () => {
        return !apf.hasIrrigationControllerView;
      },
    });

    msNavigationService.saveItem('water.licences', {
      icon: 'icon-water',
      id: 'water.licences',
      state: 'app.water.licences',
      title: 'MENU.LICENCES',
      hidden: () => {
        return !apf.hasWaterLicenceView;
      },
    });

    msNavigationService.saveItem('water.budgets', {
      icon: 'icon-cup-water',
      id: 'water.budgets',
      state: 'app.water.budgets',
      title: 'MENU.BUDGETS',
      hidden: () => {
        return !apf.hasWaterBudgetView;
      },
    });

    msNavigationService.saveItem('water.irrigation-override', {
      customIcon: true,
      icon: 'assignment-24px.svg',
      id: 'water.irrigation-override',
      state: 'app.water.irrigation-override',
      title: 'MENU.IRRIGATION_OVERRIDE',
      hidden: () => {
        return !(
          apf.hasIrrigationOverrideView &&
          apf.hasIrrigationOverride
        );
      },
    });

    // ----- CROPS -----
    msNavigationService.saveItem('crops', {
      group: true,
      title: 'MENU.CROPS',
      hidden: () => {
        return !(
          apf.hasAdminCropLibraryView ||
          apf.hasCropCyclesView ||
          apf.hasCropMetricsView
        );
      },
    });

    msNavigationService.saveItem('crops.coeff', {
      icon: 'icon-leaf',
      id: 'crops.coeff',
      state: 'app.crops.coeff',
      title: 'MENU.CROP_COEFFICIENTS',
      hidden: () => {
        return !apf.hasAdminCropLibraryView;
      },
    });

    msNavigationService.saveItem('crops.cycles', {
      icon: 'icon-chart-arc',
      id: 'crops.cycles',
      state: 'app.crops.cycles',
      title: 'MENU.CROP_CYCLES',
      hidden: () => {
        return !apf.hasCropCyclesView;
      },
    });

    msNavigationService.saveItem('crops.metrics', {
      icon: 'icon-scale',
      id: 'crops.metrics',
      state: 'app.crops.metrics',
      title: 'MENU.CROP_METRICS',
      hidden: () => {
        return !apf.hasCropMetricsView;
      },
    });

    msNavigationService.saveItem('crops.prices', {
      icon: 'icon-currency-usd',
      id: 'crops.prices',
      state: 'app.crops.prices',
      title: 'MENU.CROP_PRICES',
      hidden: () => {
        return !apf.hasCropMetricsView;
      },
    });

    msNavigationService.saveItem('crops.targets', {
      icon: 'icon-radiobox-marked',
      id: 'crops.targets',
      state: 'app.crops.targets',
      title: 'MENU.CROP_TARGETS',
      hidden: () => {
        return !apf.hasCropMetricsView;
      },
    });

    // ----- NUTRIENTS -----
    msNavigationService.saveItem('nutrients', {
      group: true,
      title: 'MENU.NUTRIENTS',
      hidden: () => {
        return !(
          apf.hasNutrientsAnalytesView ||
          apf.hasNutrientsFertilisersView ||
          apf.hasNutrientsProfilesView ||
          apf.hasNutrientsProgramsView ||
          apf.hasNutrientsSamplingView ||
          apf.hasSiteNutrientsView
        );
      },
    });

    msNavigationService.saveItem('nutrients.programs', {
      customIcon: true,
      icon: 'assignment-24px.svg',
      id: 'nutrients.programs',
      state: 'app.nutrients.programs',
      title: 'MENU.PROGRAMS',
      hidden: () => {
        return !apf.hasNutrientsProgramsView;
      },
    });

    msNavigationService.saveItem('nutrients.sampling', {
      customIcon: true,
      icon: 'science-24px.svg',
      id: 'nutrients.sampling',
      state: 'app.nutrients.sampling',
      title: 'MENU.SAMPLING',
      hidden: () => {
        return !apf.hasNutrientsSamplingView;
      },
    });

    msNavigationService.saveItem('nutrients.fertiliser', {
      icon: 'icon-blur-radial',
      id: 'nutrients.fertiliser',
      state: 'app.nutrients.fertiliser',
      title: 'MENU.FERTILISERS',
      hidden: () => {
        return !apf.hasNutrientsFertilisersView;
      },
    });

    msNavigationService.saveItem('nutrients.analytes', {
      customIcon: true,
      icon: 'polymer-24px.svg',
      id: 'nutrients.analytes',
      state: 'app.nutrients.analytes',
      title: 'MENU.ANALYTES',
      hidden: () => {
        return !apf.hasNutrientsAnalytesView;
      },
    });

    msNavigationService.saveItem('nutrients.profiles', {
      icon: 'icon-chart-areaspline',
      id: 'nutrients.profiles',
      state: 'app.nutrients.profiles',
      title: 'MENU.PROFILES',
      hidden: () => {
        return !apf.hasNutrientsProfilesView;
      },
    });

    // ----- ADMINISTRATION -----
    msNavigationService.saveItem('admin', {
      group: true,
      title: 'MENU.ADMINISTRATION',
      hidden: () => {
        return !(
          apf.hasContactsView ||
          (apf.hasAdminAccountView && apf.hasAdminAccountTimingsView) ||
          apf.hasAdminSystemAccountsView ||
          apf.hasAdminSystemUsersView ||
          apf.hasAdminUsersView
        );
      },
    });

    msNavigationService.saveItem('admin.system', {
      icon: 'icon-tooltip',
      title: 'MENU.SYSTEM_CONFIG',
      hidden: () => {
        return !apf.hasAdminSystemAccountsView;
      },
    });

    msNavigationService.saveItem('admin.system-accounts', {
      id: 'administration.system-accounts',
      state: 'app.administration.system-accounts',
      title: 'MENU.SYSTEM_ACCOUNTS',
      hidden: () => {
        return !apf.hasAdminSystemAccountsView;
      },
    });

    msNavigationService.saveItem('admin.system-users', {
      id: 'administration.system-users',
      state: 'app.administration.system-users',
      title: 'MENU.SYSTEM_USERS',
      hidden: () => {
        return !apf.hasAdminSystemUsersView;
      },
    });

    msNavigationService.saveItem('admin.account-details', {
      icon: 'icon-tooltip-text', //'icon-folder-outline', //'icon-marker-check',
      id: 'administration.account-details',
      state: 'app.administration.account-details',
      title: 'MENU.ACCOUNT_DETAILS',
      hidden: () => {
        return !(
          apf.hasAdminAccountView &&
          apf.hasAdminAccountTimingsView
        );
      },
    });

    msNavigationService.saveItem('admin.account-users', {
      icon: 'icon-account-location', //'icon-folder-account',
      id: 'administration.account-users',
      state: 'app.administration.account-users',
      title: 'MENU.ACCOUNT_USERS',
      hidden: () => {
        return !apf.hasAdminUsersView;
      },
    });

    msNavigationService.saveItem('admin.contacts', {
      icon: 'icon-people',
      id: 'administration.contacts',
      state: 'app.administration.contacts',
      title: 'MENU.CONTACTS',
      hidden: () => {
        return !apf.hasContactsView;
      },
    });

    msNavigationService.saveItem('admin.references', {
      icon: 'icon-data',
      id: 'administration.references',
      title: 'MENU.REFERENCE_LIBRARIES',
      hidden: () => {
        return !apf.hasAdminSystemAccountsView;
      },
    });

    msNavigationService.saveItem('admin.references.data-agents', {
      id: 'administration.data-agents',
      state: 'app.administration.data-agents',
      title: 'MENU.DATA_AGENTS',
      hidden: () => {
        return !apf.hasAdminSystemAccountsView;
      },
    });
  }

  public toggleNavigationDrawerPin() {
    this.folded = !this.folded;
  }
}

angular.module('app.navigation').controller('NavigationController', NavigationController);
